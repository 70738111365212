@import 'styles/global.scss';

* {
  margin: 0;
  padding: 0;
  font-family: 'Kalam';
  
}

body {
  // margin: 0px;
  // height: 100%;
  height: 100%;
  width: 100%;
  overflow: auto; /* Enable vertical scrolling */

  // overflow: hidden;
}

html {
  // scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}
.root {
  height: 100%;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 768px) {
  /* Adjust scrollbar width and handle size for smaller screens */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #555;
  }
}