@import '../../variables.scss';

.app-footer-wrapper {
    text-align: center;
    height: 30px;
    padding: 10px;
    position: sticky;
    bottom: 0;
    align-items: center;
    justify-content: center;
    

    &> .app-footer-para {
        color: #434242;
        font-size: 13px;
    }
}