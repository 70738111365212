.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-row {
    display: flex;
    flex-direction: row;
}

.app-column {
    display: flex;
    flex-direction: column;
}

.third-row {
    margin: 10px auto 20px;
    text-align: center;
    max-width: 620px;
}
