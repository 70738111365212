@import '../../variables.scss';

.app-button-wrapper {
    width: 25%;
    height: 70px;

    
    @media (max-width: 768px) {
        width: 25%;
        height: 100%;
    }

    &> .app-button {
        align-items: center;
        justify-content: center;
        display: flex;
        height: 100%;
        width: 100%;
        cursor: pointer;
        font-weight: bold;
        border-radius: 20px;
        border: none;
        // background-color: #f0efef;
        // color: #000000;
        padding: 15px 25px;
        // border: unset;
        border: 2px solid #8707ff;
        border-radius: 15px;
        color: #8707ff;
        z-index: 1;
        background: #e8e8e8;
        position: relative;
        font-weight: 1000;
        font-size: 17px;
        -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
        box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
        transition: all 250ms;
        overflow: hidden;

        @media (max-width: 768px) {
            font-size: 13px;
        }

    }

    .app-button::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        border-radius: 15px;
        background-color: #8707ff;
        z-index: -1;
        -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
        box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
        transition: all 250ms
    }
    
    .app-button:hover {
        color: #e8e8e8;
    }
    
    .app-button:hover::before {
        width: 100%;
    }
}