@import '../../variables.scss';

.app-url-box-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: calc(100vh - 170px);

    @media (max-width: 768px) {
        height: 300px;
        width: 300px;
    }
}

.url-box {
    width: 600px;
    height: 300px;
    box-shadow: 0 2px 4px #ccc;
    border-radius: 10px;
    padding: 5px 30px 10px 30px;
    text-align: center;
    color: #000000;

    @media (max-width: 768px) {
        width: 220px;
        height: 300px;
    }
}

.app-url-box-title-wrapper {
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 15px;

    &> .app-url-box-title {
        font-size: 25px;
        font-weight: bold;
        color: #ffffff;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
}

.app-url-box-input-button-wrapper {
    flex-direction: row;
    display: flex;
    padding: 15px;

    @media (max-width: 768px) {
        width: 100%;
        height: 25%;
    }
}

.app-url-box-para-wrapper {
    width: 95%;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 15px;

    
    @media (max-width: 768px) {
        width: 100%;
        height: 90px;
        justify-content: center;
        text-align: center;
        display: flex;
    }

    &> .app-url-box-para {
        font-size: 15px;
        font-weight: 300;
        color: #ffffff;

        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
}

.app-url-box-output-wrapper {
    width: 100%;
    height: 50px;
    color:#000000;
    justify-content: center;
    align-items: center;
    display: flex;
}