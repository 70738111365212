@import '../../variables.scss';

.app-input-wrapper {
    height: 70px;
    width: 100%;
    margin-right: 15px;
    box-sizing: border-box;


    &> .app-input {
        background-color: #fff;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 0;
        width: 100%;
        box-sizing: inherit;
        padding: 20px;
        color: #8707ff;
        border: 2px solid #8707ff;
        border-radius: 10px;
        background: transparent;

        // &:focus {
        //     outline: none;
        // }

        @media (max-width: 768px) {
            width: 100%; /* Make the input wrapper 80% width for small screens */
            height: 100%;

            & > .app-input {
                font-size: 10px; /* Reduce font size for smaller screens */
            }
        }
    }

    &> .app-input::placeholder {
        font-size: 17px;
        color: white;

        @media (max-width: 768px) {
            font-size: 13px; /* You can adjust the size as needed */
        }
    }
}

.app-input:active {
    box-shadow: 2px 2px 15px #8707ff inset;
}