@import '../../variables.scss';

.app-icons-box-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: calc(100vh - 50px);

    @media (max-width: 768px) {
        height: 370px; /* Allow the container to adapt to content on smaller screens */
    }
}

.app-icons-box {
    width: 55%;
    height: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        width: 80%;
        height: auto; /* Allow the container to adapt to content on smaller screens */
        justify-content: space-between;
        padding: 15px;
    }

    &> .app-icon-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 200px;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        cursor: pointer;

        @media (max-width: 768px) {
            height: 85px;
            width: 85px;
        }
    }
}

.app-icon-box:hover {
    transform: rotateY(180deg);
}

.app-icon-box-front-side, .app-icon-box-back-side {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 1rem;
    color: white;
    box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
    font-weight: 700;
}

.app-icon-box-front-side,
.app-icon-box-front-side::before {
  background: linear-gradient(43deg, rgb(65, 88, 208) 0%, rgb(200, 80, 192) 46%, rgb(255, 204, 112) 100%);
}

.app-icon-box-back-side,
.app-icon-box-back-side::before {
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
}

.app-icon-box-back-side {
  transform: rotateY(180deg);
}

.app-icon-box-front-side::before,
.app-icon-box-back-side::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
  width: 110%;
  height: 110%;
  position: absolute;
  z-index: -1;
  border-radius: 1em;
  filter: blur(20px);
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0.3;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.app-icon {
    // font-size: 48px; /* Adjust the size according to your preference */
    // max-width: 100%;
    // height: auto;
    transform: scale(1.8);
    vertical-align: middle;
    color: white;

    @media (max-width: 768px) {
        transform: scale(0.8);
    }
}


.app-icon-box-title {
    color: #4d0095;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;

    @media (max-width: 768px) {
        font-size: 8px; /* Reduce font size for better phone display */
        padding: 5px; /* Adjust padding for better phone display */

    }
}

.app-icon-box-para {
    color: #ffffff;
    font-size: 15px;
    font-weight: 360;
    line-height: 1.5;
    text-align: center;
    padding: 5px;

    @media (max-width: 768px) {
        font-size: 10px; /* Reduce font size for better phone display */
        padding: 5px; /* Adjust padding for better phone display */
    }
}