@import '../../variables.scss';

.app-header-wrapper {
    height: 40px;
    padding: 40px 20px;

    @media (max-width: 768px) {
        height: auto;
        padding: 10px;
    }
}

.app-header-title {
    font-size: 60px;
    font-weight: bold;
    color: #ffffff;
    
    @media (max-width: 768px) {
        font-size: 24px;
    }
}